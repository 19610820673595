.connect-account, .connected-account {
    margin: 0 auto;
    background: #5865F2;
    padding: 15px 15px;
    border-radius: var(--border-radius);
}

:is(.connect-account, .connected-account) .error {
    color: #FEE75C;
    margin: 10px 0 0;
    font-size: 1.2rem;
}

.connect-account .btn {
    margin: 0;
}

:is(.connect-account, .connected-account) h3 {
    font-weight: 500;
    font-size: 1.5rem;
    margin: 0;
}

.connect-account p {
    max-width: 450px;
    margin: 10px auto 15px;
}

.connected-account {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    justify-content: center;

    flex: 1;
    flex-basis: 400px;
    max-width: 600px;
}

.connected-account .user {
    text-align: center;
}

.connected-account .user p {
    margin: 0;
}

.connected-account .user .display-name {
    font-size: 1.3rem;
    font-weight: 500;
}

.connected-account .user .username {
    opacity: .7;
}

.connected-account .user .avatar {
    width: 150px;
    /*    border-radius: var(--border-radius);*/
    border-radius: 100%;
}

.connected-account .details {
    flex: 1;
    flex-basis: 250px;
}

.connected-account .details .info {
}

.connected-account .details .info p {
    max-width: 350px;
    margin: 10px 0 15px;
}
.home-view .view-title {
    text-align: left;
}

.home-view .view-title > div {
    max-width: var(--max-width);
}

.home-view .view-title .content {
    max-width: 400px;
}

.home-view .view-title .description {
    font-size: 1.1rem;
    margin-top: 5px;
}

.home-view .view-title .title {
    text-align: inherit;
}

.home-view .view-title .logo {
    width: 500px;
}

.home-view .view-title .server-ip, .home-view .view-title .server-ip * {
    font-size: 1.4rem;
    cursor: pointer;
}

.home-view .view-title .server-ip {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 8px 0 20px;
    background: var(--gray);
    width: fit-content;
    padding: 3px 16px 3px 8px;
    border-radius: var(--border-radius);
    user-select: none;
}

.home-view .view-title .server-ip span:first-child {
    padding-left: 8px;
}

@media only screen and (max-width: 1100px) {

    .home-view .view-title .logo {
        display: none;
    }

}
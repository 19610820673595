.nav-container {
    position: sticky;
    top: 0;
    background: var(--accent-light);
    width: 100%;
    z-index: 1000;
    user-select: none;
}

nav {
    --nav-height: 65px;
    display: flex;
    height: var(--nav-height);
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin: auto;
    max-width: var(--max-width);
    width: var(--width);
    padding: 0 10px;
}

nav > * {
    display: flex;
    align-items: center;
    gap: 13px;
    margin: 0;
}

nav .links a {
    font-weight: bold;
}

nav a:hover {
    text-decoration: none;
}

nav .btn {
    margin: 0;
}

nav .logo {
    max-height: calc(var(--nav-height) * .7);
}

nav .profile {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
}

nav .profile img {
    max-height: calc(var(--nav-height) * .5);
    border-radius: calc(var(--border-radius) / 2);
}

nav.mobile {
    display: none;
}

@media only screen and (max-width: 900px) {

    nav.full {
        display: none;
    }

    nav.mobile {
        display: flex;
    }

}

.nav-container .close {
    position: relative;
    cursor: pointer;
    z-index: 2000;
}

.nav-container.expanded .full {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: inherit;
    flex-direction: column;
    height: initial;
    padding: 0 0 20px;
    justify-content: flex-start;
    gap: 20px;
}

.nav-container.expanded .full .links {
    flex-direction: column;
}

.nav-container.expanded .full .logo {
    max-height: 80px;
}

.nav-container.expanded .full:first-child {
    padding-top: 10px;
}

.nav-container.expanded .mobile .logo {
    opacity: 0;
}
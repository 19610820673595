.account-view-content {
    width: var(--width);
    max-width: var(--max-width);
    margin: 40px auto;
    display: flex;
    flex-wrap: wrap;
    gap: 35px;
}

@media only screen and (max-width: 1000px) {

    .account-navbar {
        width: 100% !important;
    }

}
.currency-selector {
    --width: 65px;
    width: var(--width);
    min-width: var(--width);
    display: inline-flex;
    flex: 0;
    align-items: center;
    gap: 5px;
    border-radius: var(--border-radius);
    background: var(--gray);
    position: relative;
    z-index: 500;
    justify-content: space-between;
    padding: 5px 10px;
    font-weight: bold;
    user-select: none;
    cursor: pointer;
}

.currency-selector.expanded {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.currency-selector:not(.expanded) .other {
    display: none;
}

.currency-selector .other {
    position: absolute;
    z-index: 2;
    top: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    background: var(--gray);
    width: inherit;
    padding-left: inherit;
    padding-right: inherit;
}

.currency-selector .other p {
    padding: 8px 0;
    margin: 0;
}

.currency-selector .other p:hover {
    color: var(--light-gray);
}
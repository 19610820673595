.gamemode {
    margin: 35px 50px 35px 50px;
    width: 40%;
    min-width: 300px;
    height: min-content;
    border-radius: var(--border-radius);
    box-shadow: rgb(0, 0, 0, .2) 2px 2px 5px 1px;
}

.gamemode > .image {
    width: 100%;
    max-height: 150px;
    min-height: 50px;
    background: var(--gray);
    overflow: hidden;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    position: relative;
}

.gamemode > .image > img {
    width: 100%;
    max-height: inherit;
    min-height: inherit;
    user-select: none;
    object-position: center center;
    object-fit: cover;
}

.gamemode > .image::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    max-height: inherit;
    z-index: 500;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, var(--gray) 5px, rgb(0, 0, 0, 0) 40px);
}

.gamemode > .content {
    height: auto;
    color: white;
    background-color: var(--gray);
    position: relative;
    padding-top: 20px;
    padding-bottom: 15px;
    border-bottom-right-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
}

.gamemode > .content > .name {
    position: absolute;
    top: 0;
    z-index: 600;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--accent-dark);
    display: inline-block;
    padding: 1px 20px 1px 20px;
    margin: 0 10px 10px 0;
    min-width: 75px;
    border-radius: var(--border-radius);
    text-align: center;
    user-select: none;
    box-shadow: rgb(0, 0, 0, .2) 2px 2px 5px 1px;
    font-weight: bold;
}

.gamemode > .content > .description {
    width: 80%;
    min-width: 250px;
    font-weight: 300;
    margin: auto;
    text-align: center;
}
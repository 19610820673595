.store {
    width: var(--width);
    max-width: var(--max-width);
    margin: 40px auto;
    display: flex;
    flex-wrap: wrap;
    gap: 35px;
}

.store .category {
    flex: 1;
}

.store .category .title {
    display: flex;
    align-items: flex-end;
    position: relative;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.store .category .title h1 {
    font-family: 'Rubik', sans-serif;
    font-size: 2rem;
    font-weight: bold;
    text-align: left;
    margin: 0 10px 0 0;
}

.store .category .title p {
    margin: 0;
    color: var(--light-gray);
    font-weight: 500;
    text-align: left;
}

.store .category .title::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -5px;
    transform: translateY(100%);
    width: 100%;
    height: 5px;
    border-radius: var(--border-radius);
    background-color: var(--gray);
}

.store .category .items {
    display: flex;
    flex-wrap: wrap;
    padding-top: 30px;
    gap: 30px 50px;
    justify-content: center;
}
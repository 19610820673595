.login-container {
    position: relative;
    display: flex;
    align-items: stretch;
    padding: 30px 0;
    width: 700px;
    max-width: 100vw;
    background-color: var(--gray);
    margin: 70px auto 150px auto;
    border-radius: var(--border-radius);
    overflow: auto;
}

.login-container .btn {
    margin: 0;
}

.login-container .classic, .login-container .discord {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    height: inherit;
    margin: 0 35px;
}

.login-container > .splitter {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 3px;
    height: 80%;
    border-radius: var(--border-radius);
    background-color: var(--gray);
    filter: brightness(1.2);
}

.login-container .classic label {
    font-weight: 500;
}

.login-container .classic .form p {
    font-size: 1.1rem;
    text-align: center;
    margin-top: 0;
}

.login-container > .discord p {
    font-size: 1.1rem;
    text-align: center;
    margin-bottom: 30px;
}

.login-container .discord .btn svg {
    height: 1.2rem;
    fill: white;
}

@media only screen and (max-width: 900px) {
    .login-container {
        width: 350px;
        flex-direction: column;
    }

    .login-container > .splitter {
        position: initial;
        transform: none;
        margin: 30px auto 30px auto;
        height: 3px;
        width: 80%;
        border-radius: var(--border-radius);
        background-color: var(--gray);
        filter: brightness(1.2);
    }
}
.switch-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
}

.switch-input .label, .switch-input .label a {
    margin: 0 6px 0 0;
    color: var(--white);
    font-size: 1.2rem;
}

.switch-input .label a {
    color: var(--accent-light);
}

.switch-input label {
    --height: 23px;
    --padding: 3px;
    position: relative;
    display: block;
    width: calc(var(--height) * 1.8);
    min-width: calc(var(--height) * 1.8);
    height: var(--height);
    background-color: rgb(230, 230, 230);
    border-radius: calc(var(--border-radius) * 1.5);
    padding: var(--padding);
    cursor: pointer;
}

.switch-input input {
    opacity: 0;
    width: 0;
    height: 0;
    margin: 0;
    position: absolute;
}

.switch-input .slider {
    --notToggledLeft: var(--padding);
    --toggledLeft: calc(100% - var(--padding) - var(--height));
    --width: calc(var(--height));
    position: absolute;
    display: inline-block;
    width: var(--width);
    height: calc(var(--height));
    left: var(--notToggledLeft);
    background-color: var(--accent-dark);
    border-radius: inherit;
    transition: filter 250ms, left 250ms, width 250ms;
    filter: grayscale(80%);
}

.switch-input input:checked + .slider {
    left: var(--toggledLeft);
    filter: none;
}

.switch-input input:active + .slider {
    width: calc(1.2 * var(--width));
    filter: grayscale(40%);
}

.switch-input input:active:checked + .slider {
    left: calc(var(--toggledLeft) - .2 * var(--width));
}
.store-item {
    --width: 300px;
    --img-height: calc(.65 * var(--width));
    position: relative;
    display: flex;
    flex-direction: column;
    width: var(--width);
    background-color: var(--gray);
    border-radius: calc(var(--border-radius) * 2);
    padding-top: calc(.5 * var(--img-height));
    margin-top: calc(.5 * var(--img-height));
    text-align: center;
}

.store-item .img-container {
    position: absolute;
    display: flex;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: var(--img-height);
    height: fit-content;
    padding: 4px;
    user-select: none;
}

.store-item .img-container img {
    width: 100%;
}

.store-item .img-container.round-margin {
    border-radius: 100%;
    background-color: var(--dark-gray);
}

.store-item h3 {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 5px 0 0;
}

.store-item .bonus {
    margin: 0;
    font-size: 1.1em;
    color: var(--green);
}

.store-item .bonus::before {
    content: '+ ';
}

.store-item .price {
    font-size: 1.2rem;
    margin: 2px 0 0;
}

.store-item .price .gems::before {
    content: '/';
    margin: 0 5px;
    color: var(--light-gray);
}

.store-item .price .gems {
    font-size: 1.1rem;
    color: var(--green);
}

.store-item .duration {
    color: var(--accent-dark);
    text-transform: uppercase;
    margin: 0 0 5px;
}

.store-item .featured {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 15px 0;
}

.store-item .featured p {
    margin: 0;
    padding: 0 10px;
}

.store-item .featured p:not(.label) {
    color: var(--light-gray);
}

.store-item .featured .label {
    font-size: 1.2rem;
}

.store-item .bottom-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
}
.btn {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    padding: 5px 20px 5px 20px;
    margin: 0 10px 10px 0;
    min-width: 60px;
    min-height: 23px;
    border-radius: var(--border-radius);
    text-align: center;
    transition: background .3s;
    cursor: pointer;
    color: white;
    user-select: none;
    overflow: hidden;
    font-weight: 500;
}

.btn.loading {
    min-width: 100px;
    padding-left: 0;
    padding-right: 0;
}

.btn .loader {
    margin: 0 auto;
    width: 17px;
    height: 17px;
    border-width: 4px;
    --loader-color: white;
}

.btn.disabled {
    box-shadow: inset 0 0 1000px 0 rgb(0, 0, 0, 0.3);
    color: #9e9eab;
    cursor: not-allowed;
}

.btn-primary {
    background-color: var(--accent-dark);
}

.btn-secondary {
    background-color: rgb(41, 51, 77, .3);
    border: solid 1px rgb(41, 51, 77);
}

.btn-primary:not(.disabled):not(.loading):hover, .btn-primary:not(.disabled):not(.loading):active {
    background-color: #1d62ca;
}

.btn-secondary:not(.disabled):not(.loading):hover, .btn-secondary:not(.disabled):not(.loading):active {
    background-color: rgb(41, 51, 77, .4);
}

.btn-green {
    background-color: var(--green);
}

.btn-green:not(.disabled):hover, .btn-green:not(.disabled):active {
    background-color: #0c8d46;
}

.btn-red {
    background-color: var(--red);
}

.btn-red:not(.disabled):hover, .btn-red:not(.disabled):active {
    background-color: #d4283c;
}

a:has(.btn) {
    display: contents;
}
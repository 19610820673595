.payment-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    max-width: calc(var(--max-width) * 0.6);

    flex: 1;
    flex-basis: 400px;
}

.payment-list .btn {
    flex: 0;
}

.payment-list > p {
    margin: 0;
    font-size: 1.3rem;
}

.side-navbar {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.side-navbar .navbar-title {
    width: 100%;
    display: inline-flex;
    position: relative;
    gap: 10px;
    justify-content: space-between;
    font-family: 'Rubik', sans-serif;
    font-size: 2rem;
    font-weight: bold;
}

.side-navbar .navbar-title::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -5px;
    transform: translateY(100%);
    width: inherit;
    height: 5px;
    border-radius: var(--border-radius);
    background-color: var(--accent-dark);
}

.side-navbar .entries {
    user-select: none;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.side-navbar .entries > a {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: var(--border-radius);
    background: rgb(20, 20, 20, .3);
    gap: 10px;
    margin: 0;
    padding: 8px 12px;
    text-decoration: none;
    font-weight: 500;
    font-size: 1.1rem;
}

.side-navbar .entries > a.selected {
    background: var(--accent-dark);
}

.side-navbar .widgets {
    display: flex;
    flex-direction: column;
    gap: 25px;
}
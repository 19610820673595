.store-checkout-view .checkout {
    margin: 30px auto;
    width: var(--width);
    max-width: 350px;
}

.store-checkout-view .checkout .items {
    display: flex;
    gap: 10px;
    align-items: center;
    flex-direction: column;
    margin-top: 15px;
}

.store-checkout-view .checkout .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: var(--border-radius);
    background: var(--gray);
    padding: 8px 0;
}

.store-checkout-view .checkout .item .name {
    display: inline-flex;
    align-items: center;
    margin: 0 0 0 15px;
}

.store-checkout-view .checkout .item .item-name {
    font-size: 1.3rem;
    margin-right: 10px;
}

.store-checkout-view .checkout .item .price {
    font-size: 1.2rem;
    color: var(--light-gray);
    margin: 0 15px 0 0;
}

.store-checkout-view .checkout .item .remove {
    stroke-width: 3px;
    cursor: pointer;
    color: var(--red);
    margin-right: 15px;
}


.store-checkout-view .checkout .item:not(:hover) .remove {
    display: none;
}

.store-checkout-view .checkout .item:hover .price {
    display: none;
}

.store-checkout-view .checkout .summary {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 15px 0;
}

.store-checkout-view .checkout .summary * {
    margin: 0;
    font-size: 1.3rem;
}

.store-checkout-view .label {
    margin: 8px 0 4px;
    font-size: 1.2rem;
}

.store-checkout-view .input-field input {
    background: var(--gray);
    padding: 6px;
    margin: 0 0 4px;
    font-size: 1.1rem;
    transition: box-shadow 200ms;
}

.store-checkout-view .input-field input.invalid {
    box-shadow: 0 0 0 2px var(--red);
}

.store-checkout-view .checkout .btn {
    display: block;
    margin: 15px 0 0;
}

.store-checkout-view .paywall {
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 10px;
    padding: 5px;
    margin-bottom: 5px;
    user-select: none;
    transition: background-color 150ms;
}

.store-checkout-view .paywall.disabled {
    color: var(--light-gray);
}

.store-checkout-view .paywall.selected {
    background: var(--accent-dark);
}

.store-checkout-view .paywall.enabled:not(.selected):hover {
    background: rgb(200, 200, 200, .2);
}

.store-checkout-view .paywall.enabled {
    cursor: pointer;
}

.store-checkout-view .paywall.disabled {
    cursor: default;
}

.store-checkout-view .paywall svg {
    width: 10%;
    height: auto;
    margin-bottom: auto;
}

.store-checkout-view .paywall .info {
    flex: 1;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
}

.store-checkout-view .paywall .info p {
    margin: 0;
}

.store-checkout-view .paywall .name {
    font-size: 1.2rem;
}
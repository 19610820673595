.footer-container {
    width: 100%;
    height: auto;
    background-color: var(--gray);
}

footer {
    max-width: var(--max-width);
    width: var(--width);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    height: auto;
    margin: auto;
    padding-top: 30px;
    padding-bottom: 30px;
    color: white;
    gap: 10px 50px;
}

footer svg {
    margin-top: 10px;
}

.footer-col {
    float: left;
    margin: 0;
    padding: 0;
    list-style: none;
}

.footer-col > li, .footer-col > li * {
    margin: 0;
    font-size: 1.1rem;
}

footer li.bold {
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 10px;
}

.footer-col.col2 {
    flex: 2;
    min-width: min(300px, 100%);
}

.footer-col.col3 {
    flex: 1;
    min-width: min(150px, 100%);
}

.copyright {
    background-color: var(--dark-gray);
}

.copyright > p {
    max-width: var(--max-width);
    width: var(--width);
    margin: auto;
    color: white;
    font-size: 1.3rem;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 500;
}
@keyframes placeholder-bg {
    0% {
        background-color: rgba(143, 137, 137, .8);
    }
    50% {
        background-color: rgb(143, 137, 137, .6);
    }
    100% {
        background-color: rgb(143, 137, 137, .8);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

:root {
    --dark-gray: #1F263A;
    --gray: #29334D;
    --light-gray: #C6C7CC;
    --white: #fff;
    --accent-dark: #196CE9;
    --accent-light: #2ECEFF;
    --red: #e63449;
    --green: #089e4e;
    --discord: #5865f2;
    --loader-color: var(--light-gray);
    --max-width: 1500px;
    --width: 80%;
    --border-radius: 6px;
}

body, html {
    overscroll-behavior: none;
}

body {
    margin: 0;
    min-width: 375px;
    background-color: var(--dark-gray);
    color: var(--white);
    font-family: Poppins, serif;
    outline: none;
}

* {
    font-size: 1rem;
}

.placeholder {
    animation: placeholder-bg 2s infinite;
    cursor: wait !important;
    color: transparent !important;
    border-radius: var(--border-radius);
}

.auto-width-app {
    margin-left: auto;
    margin-right: auto;
    width: var(--width);
    max-width: var(--max-width);
}

.max-width-app {
    max-width: var(--max-width);
}

a:link, a:visited, a:link:active, a:visited:active {
    color: white;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

.link-color {
    color: #42b6ee !important;
}

input[type=text], input[type=password] {
    display: inline-block;
    box-sizing: border-box;
    border-radius: var(--border-radius);
    border: none;
    background-color: var(--dark-gray);
    padding: 4px 8px;
    color: white;
    margin-top: 5px;
    margin-bottom: 25px;
    width: 100%;
    font-weight: 500;
}

textarea:focus, input:focus {
    outline: none;
}

label {
    color: white;
    float: none;
}

.loader {
    --border-width: 4px;
    border: var(--loader-color) solid var(--border-width);
    border-bottom-color: transparent;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 1s ease-in-out infinite;
}

.loader.big {
    --border-width: 6px;
    width: 35px;
    height: 35px;
}

.loader.margin {
    margin: 20px;
}

.loader.center {
    margin-left: auto;
    margin-right: auto;
}

.small-shadow {
    box-shadow: rgb(0, 0, 0, .2) 2px 2px 5px 1px;
}

@media only screen and (max-width: 600px) {

    :root {
        --width: 90%;
    }

}

img {
    user-select: none;
}
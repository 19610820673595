.title {
    font-family: 'Rubik', sans-serif;
    margin: 0;
    font-size: 2.6rem;
    font-weight: bold;
    color: white;
    text-align: center;
}

.title.bold {
    font-family: 'Rubik Mono One', sans-serif;
}
@keyframes gift-show {
    0% {
        right: 8px;
    }
    100% {
        right: 0;
    }
}

.cart .btn {
    margin: 10px;
    display: block;
}

.cart .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    padding: 5px 10px;
}

.cart .item .price {
    font-size: .9rem;
    margin: 0;
    color: var(--light-gray);
}

.cart .item:hover .price {
    display: none;
}

.cart .item .remove {
    cursor: pointer;
    color: var(--red);
    stroke-width: 3px;
}

.cart .item:not(:hover) .remove {
    display: none;
}

.cart .item .name {
    display: flex;
    align-items: center;
    gap: 10px;
}

.cart .item:nth-child(2n+1) {
    background: rgb(0, 0, 0, .1);
}

.cart .item:nth-child(2n) {
    background: rgb(255, 255, 255, .1);
}

.cart .item .name {
    margin: 0;
    font-weight: 500;
}

.gift-tag {
    color: var(--white);
    z-index: 100;
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
    display: inline-block;
    position: relative;
    background: var(--red);
    cursor: pointer;
    user-select: none;
}

.gift-tag:not(:hover) {
    border-radius: var(--border-radius);
}

.gift-tag svg {
    vertical-align: middle;
    padding: 2px;
}

.gift-tag .user {
    position: absolute;
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 0 8px 0 3px;
    height: 100%;
    z-index: -1;
    top: 0;
    right: 0;
    transform: translateX(100%);
    background: inherit;
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
}

.gift-tag:not(:hover) .user {
    padding: 0;
    width: 0;
}

.gift-tag:hover .user {
    width: inherit;
    animation: gift-show 150ms ease-out;
}

.cart .summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    padding: 5px 10px 0;
    border-top: var(--light-gray) 2px solid;
    font-weight: 500;
}

.cart .summary p {
    font-size: 1.2rem;
    margin: 0;
}
.dropdown-parent {
    position: relative;
    cursor: pointer;
}

.dropdown {
    background-color: var(--gray);
    box-shadow: black 1px 1px 4px -2px;
    width: max-content;
    min-width: 150px;
    display: none;
    border-radius: var(--border-radius);
    list-style-type: none;
    padding: 6px 6px 4px;
    position: absolute;
    top: 130%;
    z-index: 1000;
    overflow: hidden;
}

.dropdown.left {
    right: 0;
}

.dropdown.right {
    left: 0;
}

.dropdown.center {
    left: 50%;
    transform: translateX(-50%);
}

.dropdown.expanded {
    display: block;
}

.dropdown .item {
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--white);
    padding: 3px 10px 3px 5px;
    margin: 0 0 2px 0;
    border-radius: var(--border-radius);
    transition: background .2s;
}

.dropdown .item:hover {
    background-color: rgb(255, 255, 255, .1);
}

.dropdown .item.red {
    color: var(--red);
}

.dropdown .item.green {
    color: var(--green);
}

a:has(.dropdown .item) {
    display: contents;
}
.payment-status-view .status {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    margin: 50px 20px;

    color: var(--light-gray);
    font-weight: bold;
}

.payment-status-view .status .icon {
    --size: 45px;
    --width: var(--size);
    width: var(--width);
    height: var(--width);
}

.payment-status-view .icon.loader {
    --border-width: 5px;
    --width: calc(var(--size) - 2 * var(--border-width) - 6px);
}

.payment-status-view .status :is(h2, p:not(.sms-info)) {
    margin: 0;
    text-align: center;
    max-width: 400px;
}

.payment-status-view .status h2 {
    font-size: 1.5rem;
}

.payment-status-view .sms-info {
    font-size: 1.2rem;
    max-width: 400px;
    text-align: center;
    color: var(--white);
}

.payment-status-view .sms-info-provider {
    padding-top: 30px;
    font-size: 1rem;
    color: var(--light-gray);
}

.payment-status-view .sms-info :is(span, a) {
    font-size: 1.3rem;
    color: var(--accent-light);
}

.payment-status-view .sms-info a {
    text-decoration: underline;
}

.payment-status-view .status .description {
    font-size: 1.1rem;
    filter: opacity(.8);
    font-weight: 500;
    color: var(--white);
}

.payment-status-view .status.red {
    color: var(--red);
}

.payment-status-view .status.green {
    color: var(--green);
}
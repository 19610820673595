.text {
    color: white;
    font-size: 1.3rem;
    max-width: 90vw;
}

.text.red {
    color: var(--red);
}

.text.green {
    color: var(--green);
}

p.text {
    text-align: center;
    margin: 10px auto;
}

.text.bold {
    font-weight: bold;
}


.text.highlighted {
    color: var(--accent-light);
}

.text.spacing {
    margin: 35px auto 25px auto;
}

.text.placeholder {
    display: inline-block;
    width: fit-content;
    user-select: none;
    min-width: 10px;
}
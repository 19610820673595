.store-item-modal-container {
    overflow: auto;
    z-index: 10000;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    width: calc(100svw - 50px);
    max-width: 900px;
    --max-height: calc(100svh - 50px);
    min-height: min(500px, var(--max-height));
    max-height: var(--max-height);

    background: var(--gray);
    box-shadow: rgb(0, 0, 0, .4) 0 0 0 100vw;
    border-radius: var(--border-radius);
}

.store-item-modal {
    display: flex;
    flex-wrap: wrap-reverse;
    min-height: inherit;
}

.store-item-modal-container .close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

.store-item-modal .properties {
    text-align: left;
    flex-basis: 400px;
    flex-grow: 3;
    padding: 15px;
}

.store-item-modal .properties h3 {
    margin: 0;
    font-size: 1.8rem;
}

.store-item-modal .bonus::before {
    content: '+ ';
}

.store-item-modal .bonus {
    margin: 0;
    color: var(--green);
}

.store-item-modal .description {
    margin: 5px 0 0;
    color: var(--light-gray);
}

.store-item-modal .benefits h4 {
    margin: 10px 0 5px;
    font-size: 1.3rem;
    font-weight: bold;
    color: var(--white);
}

.store-item-modal .benefits .inherit {
    margin: 0;
    color: var(--light-gray);
}

.store-item-modal .benefits .inherit .parent {
    color: var(--accent-light);
}

.store-item-modal .benefits .section h5 {
    margin: 10px 0 5px;
    font-size: 1.2rem;
    font-weight: 500;
}

.store-item-modal .benefits .benefit::before {
    color: var(--light-gray);
    content: '- ';
    margin-right: 5px;
}

.store-item-modal .benefits .benefit {
    margin: 0 5px 3px;
}

.store-item-modal .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 250px;
    flex-grow: 2;
    padding: 15px;
    background: var(--dark-gray);
}

.store-item-modal img {
    width: 90%;
    max-width: 200px;
    user-select: none;
    margin: 10px 0;
}

.store-item-modal .price {
    font-size: 1.3rem;
}

.store-item-modal .price .gems {
    font-size: 1.2rem;
}

.store-item-modal .duration {
    font-size: 1.2rem;
}

.store-item-modal .button-container {
    display: flex;
    width: calc(100% - 20px);
    flex-direction: column;
    justify-content: flex-end;
    padding: 10px 0;
    flex: 1;
    gap: 20px;
}

.store-item-modal .button-container .gift {
    display: flex;
    flex-direction: column;
    border-radius: var(--border-radius);
    overflow: hidden;
    transition: height 200ms;
    background: rgb(230, 52, 73, .2);
}

.store-item-modal .button-container .gift .btn {
    margin: 0;
    border-radius: 0;
}

.store-item-modal .button-container .gift input {
    margin: 0;
    padding: 10px;
    text-align: center;
    background: transparent;
}

.store-item-modal .button-container .gift input::placeholder {
    opacity: 1;
    color: var(--light-gray);
}

.store-item-modal .button-container .gift input.error:not(:placeholder-shown) {
    color: var(--red);
}

.store-item-modal .button-container .gift:not(.active) input {
    display: none;
}

.store-item-modal .button-container .buy {
    width: 100%;
    display: flex;
    gap: 20px;
}

.store-item-modal .button-container .buy .btn {
    margin: 0;
    flex-grow: 1;
}
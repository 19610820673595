.payment {
    width: 100%;

    background: var(--gray);
    overflow: hidden;
    border-radius: var(--border-radius);
}

.payment .btn {
    margin: 0;
    width: auto;
    min-width: fit-content;
}

.payment :is(.fields, .content) {
    width: 100%;
    display: flex;

    align-items: center;
    gap: 10px;
}

.payment .fields {
    user-select: none;
    cursor: pointer;
}

.payment.expanded .fields {
    border-bottom: 3px solid var(--dark-gray);
}

.payment .fields p, .payment .state {
    font-weight: 500;
    margin: 0;
    padding: 10px 0;
}

.payment :is(.date, .state) {
    background: rgb(255, 255, 255, .25);
    text-align: center;
    min-width: 100px;
    flex-basis: 100px;
}

.payment .date {
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
}

.payment.pending :is(.date, .state) {
    background: rgb(255, 163, 60, .25);
}

.payment.completed :is(.date, .state) {
    background: rgb(8, 158, 78, .25);
}

.payment.canceled :is(.date, .state) {
    background: rgb(230, 52, 73, .25);
}

.payment.refunded :is(.date, .state) {
    background: rgb(230, 52, 73, .25);
}

.payment .item-preview {
    flex: 1;
}

.payment .total {
    font-weight: bold;
}

.payment .expand-icon {
    stroke-width: 3px;
    margin-right: 10px;
    transition: transform 200ms;
}

.payment.expanded .expand-icon {
    transform: rotate(-180deg);
}

.payment .content {
    align-items: stretch;
}

.payment .state {
    user-select: none;
}

.payment .items {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px 0;
    gap: 10px;
}

.payment .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 45px;
    text-align: center;
}

.payment .item p {
    margin: 0;
}

.payment .item .name {
    display: flex;
    gap: 10px;
    align-items: center;
    font-weight: 500;
}

.payment .item .price {
    color: var(--light-gray);
}

@media only screen and (max-width: 600px) {

    .payment .item-preview {
        display: none;
    }

    .payment .date {
        margin-right: auto !important;
    }

    .payment .item {
        margin-right: 10px;
    }

}
.store-navbar {
    width: 250px;
}

@media only screen and (max-width: 750px) {

    .store-navbar {
        width: 100% !important;
    }

}
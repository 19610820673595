.store-widget {
    background: var(--gray);
    border-radius: var(--border-radius);
}

.store-widget h3 {
    color: var(--white);
    margin: 5px 10px 10px;
    font-family: 'Rubik', sans-serif;
    font-size: 1.5rem;
    user-select: none;
}
.document {
    text-align: center;
}

.document :is(h1, h3) {
    font-family: 'Rubik', sans-serif;
    margin: 0;
    font-weight: bold;
    color: white;
}

.document h1 {
    font-size: 2.6rem;
    margin: 25px 0;
}

.document h3 {
    font-size: 1.6rem;
    margin: 20px 0 10px;
}

.document ol {
    padding: 0;
    margin: 0;
    list-style: none;
}

.document li {
    margin-top: 10px;
}

.document li::before {
    content: counter(list-item) ". ";
    color: var(--accent-light);
    display: inline;
}

.document ol[type="A"] li {
    margin-top: 5px;
}

.document ol[type="A"] li::before {
    content: counter(list-item, lower-alpha) ") ";
}
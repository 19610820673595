.customer-of-month {
    display: flex;
    gap: 10px;
    align-items: flex-start;
    padding: 0 10px;
    margin-bottom: 10px;
    max-width: 100%;
}

.customer-of-month img {
    user-select: none;
    width: 70px;
    height: 70px;
    background: rgb(255, 255, 255, .3);
    border-radius: var(--border-radius);
}

.customer-of-month .details {
    overflow: auto;
}

.customer-of-month .details p {
    margin: 0;
    font-size: 1.3em;
}

.customer-of-month .details .name {
    overflow-wrap: break-word;
    font-weight: 500;
}

.customer-of-month .details .value {
    color: var(--light-gray);
}
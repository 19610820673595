.view-title {
    min-height: 100px;
    background: linear-gradient(0deg, #196CE9 -100%, var(--accent-light) 100%);
    padding: 70px max(calc((100% - var(--max-width)) / 2), (100% - var(--width)) / 2);
}

.view-title > * {
    max-width: var(--max-width);
}

.view-title ::selection {
    background: rgb(255, 255, 255, .4);
}

@media only screen and (max-width: 500px) {

    .view-title {
        padding-left: 20px;
        padding-right: 20px;
        text-align: center !important;
    }

}
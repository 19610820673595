.gem-payment-modal-container {
    overflow: auto;
    z-index: 10000;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    width: calc(100svw - 50px);
    max-width: 350px;
    --max-height: calc(100svh - 50px);
    min-height: min(500px, var(--max-height));
    max-height: var(--max-height);

    background: var(--gray);
    box-shadow: rgb(0, 0, 0, .4) 0 0 0 100vw;
    border-radius: var(--border-radius);
}

.gem-payment-modal-container .close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

.gem-payment-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: inherit;
    gap: 5px;
}

.gem-payment-modal.loading {
    justify-content: center;
    align-items: center;
}

.gem-payment-modal img {
    width: calc(100% - 50px);
    max-width: 250px;
    height: auto;
    margin-top: 10px;
}

.gem-payment-modal h3 {
    margin: 0;
    font-size: 1.5rem;
}

.gem-payment-modal .price {
    margin: 0;
    color: var(--green);
    font-size: 1.2rem;
}

.gem-payment-modal .gift {
    display: inline-flex;
    align-items: center;
    gap: 4px;

    background: var(--red);
    border-radius: var(--border-radius);

    margin: 0;
    padding: 3px 5px;

    user-select: none;
}

.gem-payment-modal .new-balance {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 0 0 5px;

    font-size: 1.1rem;
}

.gem-payment-modal .new-balance span {
    display: inline-block;
    font-size: 1.2rem;
    color: var(--green);
}

.gem-payment-modal .spacer {
    width: 100%;

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: flex-end;
    gap: 10px;

    margin: 10px 0;

    flex: 1;
}

.gem-payment-modal .status {
    color: var(--red);
    margin: 0;

    text-align: center;
    font-size: 1.1rem;
}

.gem-payment-modal .btn {
    margin: 0;
}

.gem-payment-modal .error {
    text-align: center;
    font-size: 1.1rem;
    margin: 0;

    color: var(--red);
}

.gem-payment-modal .success {
    color: var(--green);
}

.gem-payment-modal .success p {
    flex: 1;
    display: inline-flex;
    gap: 5px;
    justify-content: center;
    align-items: center;

    text-align: center;
    font-size: 1.2rem;
    margin: 0;
}